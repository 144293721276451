import { APIClient } from "./api_frontend_helper";

import * as url from "./url_frontend_helper";

const api = new APIClient();

// Messages
// get Messages
export const getMessages = (caseExtId) => api.get(url.GET_MESSAGES, caseExtId); //GET
export const sendMessage = (data) => api.create(url.SEND_MESSAGE, data); //POST
export const setMessageRead = (msgId) => api.get(url.SET_MESSAGE_READ, msgId); //GET
//export const getMessages = caseExtId => api.get(url.GET_MESSAGES, caseExtId); //GET
//export const getMessages = caseExtId => api.create(url.GET_MESSAGES, caseExtId); //POST
// get Cases
//export const getCases = () => api.get(url.GET_CASES);
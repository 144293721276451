import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getMessages as getMessagesApi,
  sendMessage as sendMessageApi,
  setMessageRead as setMessageReadApi,
} from "@./helpers/frontend_helper";

export const getMessages = createAsyncThunk("messages/getMessages", async (caseExtId) => {
  try {
    const response = getMessagesApi(caseExtId);
    return response;
  } catch (error) {
    return error;
  }
});

export const sendMessage = createAsyncThunk("messages/sendMessage", async (msgData) => {
  try {
    const response = sendMessageApi(msgData);
    const data = await response;
    toast.success("Nachricht erfolgreich verschickt", { autoClose: 3000, containerId: "chatToast" });
    return data;
  } catch (error) {
	toast.error("Fehler beim Nachrichtenversand", { autoClose: 3000, containerId: "chatToast" });
    return error;
  }
});

export const setMessageRead = createAsyncThunk("messages/setMessageRead", async (msgId) => {
  try {
    const response = setMessageReadApi(msgId);
    const data = await response;
    return data;
  } catch (error) {
    toast.error("Nachricht" + msgId + " konnte nicht als gelesen markiert werden.", { autoClose: 3000, containerId: "chatToast" });
    return error;
  }
});
import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { /*getMessages, */sendMessage, setMessageRead } from './thunk';
export const initialState = {
	/*messages: [],
	messageUsers: [],
	isMsgSuccess: false,*/
	isMessageSent: false,
	isMessageRead: [],
};

const MessagesSlice = createSlice({
  name: 'MessagesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    /*builder.addCase(getMessages.fulfilled, (state, action) => {
		console.log("yeah1");
		console.log(action);
		
		if(action.payload){
			console.log(action.payload);
			console.log(action.payload.data);
			const data = JSON.parse(action.payload);
			console.log("hallo1");
			console.log(data);
			console.log("hallo2");
			if(Object.keys(data).length>0){
				state.messages = data.msgs;
				state.messageUsers = data.msgs_users;
				state.error = data.error?.message || null;
				state.isMsgSuccess = true;
			} else {
				state.isMsgSuccess = false;
			}
		}
    });*/

    /*builder.addCase(getMessages.rejected, (state, action) => {
	  state.error = action.error?.message || null;
    });*/
	
    builder.addCase(sendMessage.fulfilled, (state, action) => {
		if(action.payload?.messageSaved && action.meta?.arg?.caseId){
			state.isMessageSent = action.payload?.messageSaved;
			if(action.payload?.error?.hasError){
				action.payload?.error?.errorMsg?.forEach((thisErrorMsg) => toast.error(thisErrorMsg, { autoClose: 6000, containerId: "chatToast" }));
			}
		} else {
			toast.error("Keine Daten", { autoClose: 6000, containerId: "chatToast" })
		}
		state.isMessageSent = false;
    });

    builder.addCase(sendMessage.rejected, (state, action) => {
		if(action.error?.message){
			toast.error(action.error?.message, { autoClose: 6000, containerId: "chatToast" });
		}
    });
	
    builder.addCase(setMessageRead.fulfilled, (state, action) => {
		if(action.payload?.messageRead && action.meta?.arg?.msgId){
			state.isMessageRead[action.meta.arg.msgId] = action.payload?.messageRead;
			if(action.payload?.error?.hasError){
				action.payload?.error?.errorMsg?.forEach((thisErrorMsg) => toast.error(thisErrorMsg, { autoClose: 6000, containerId: "chatToast" }));
			}
		} else {
			toast.error("Keine Daten", { autoClose: 6000, containerId: "chatToast" })
		}
    });

    builder.addCase(setMessageRead.rejected, (state, action) => {
		if(action.error?.message){
			toast.error(action.error?.message, { autoClose: 6000, containerId: "chatToast" });
		}
    });
  }
});

export default MessagesSlice.reducer;